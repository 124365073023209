import MediaUpload from '../../../components/MediaUpload.vue';
import MediaList from '../../../components/MediaList.vue';
import { frontendURL } from '../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/dashboard/media/upload'),
      component: MediaUpload,
      roles: ['administrator', 'agent'],
      name: 'media_upload'
    },
    {
      path: frontendURL('accounts/:accountId/dashboard/media'),
      component: MediaList,
      roles: ['administrator', 'agent'],
      name: 'media_list'
    },
  ],
};