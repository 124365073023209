<template>
  <div style="margin: 200px 250px 0 0;">
    <div class="left-wrap">
      <file-upload
          ref="upload"
          v-tooltip.top-end="$t('CONVERSATION.REPLYBOX.TIP_ATTACH_ICON')"
          :size="4096 * 4096"
          :accept="allowedFileTypes"
          :drop="true"
          :drop-directory="false"
          @input-file="onFileUpload"
        >
        <woot-button          
          class-names="button--upload"
          :title="$t('CONVERSATION.REPLYBOX.TIP_ATTACH_ICON')"
          icon="attach"
          emoji="📎"
          color-scheme="secondary"
          variant="smooth"
          size="medium"
        />
      </file-upload>
    </div>

    <div class="upload-message" v-if="uploadFinished">
      <span>File uploaded successfully!
        <a :href="`${uploadFinished.url}`" target="_blank">
          <fluent-icon
            v-tooltip.right-start="$t('Download')"
            icon="open"
            class="action--icon cursor-pointer"
            size="16"
          />
        </a>
      </span>
    
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FileUpload from 'vue-upload-component';
import * as ActiveStorage from 'activestorage';
import { ALLOWED_FILE_TYPES } from '../../shared/constants/messages';
import { checkFileSizeLimit } from '../../shared/helpers/FileHelper';
import { DirectUpload } from 'activestorage';

export default {
  name: 'MediaUpload',
  components: {
    FileUpload,
  },
  props: {
    onSend: {
      type: Function,
      default: () => {},
    },
    sendButtonText: {
      type: String,
      default: 'Upload',
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      globalConfig: 'globalConfig/get',
      accountId: 'getCurrentAccountId',
    }),
    allowedFileTypes() {
      return ALLOWED_FILE_TYPES;
    },
    buttonClass() {
      return {
        warning: this.isNote,
      };
    },
  },
  data() {
    return {
      files: [],
      uploadURL: '/upload', // replace with your upload endpoint
      uploadFinished: null,
    };
  },
  mounted() {
    ActiveStorage.start();
  },
  methods: {
    inputFilter(newFile, oldFile, prevent) {
      // filter for file types
    },
    inputFile(newFile, oldFile) {
      // handle file input
    },
    onFileUpload(file) {
      this.onDirectFileUpload(file);
    },
    showAlert(message, action) {
      bus.$emit('newToastMessage', message, action);
    },
    onDirectFileUpload(file) {
      this.uploadFinished = null;
      const MAXIMUM_SUPPORTED_FILE_UPLOAD_SIZE = 40;
      if (!file) {
        return;
      }
      if (checkFileSizeLimit(file, MAXIMUM_SUPPORTED_FILE_UPLOAD_SIZE)) {
        const upload = new DirectUpload(
          file.file, 
          `/api/v1/accounts/${this.accountId}/media_uploads`,
          {
            directUploadWillCreateBlobWithXHR: xhr => {
              xhr.setRequestHeader(
                'api_access_token',
                this.currentUser.access_token
              );
            },
          }
        );

        upload.create((error, blob) => {
          if (error) {
            // console.log('-----------------------')
            // console.log('ERROR ON UPLOADING FILE')
            // console.log('-----------------------')
            this.showAlert(`ERROR:\n\n ${error}`);
          } else {
            console.log('-----------------------')
            // console.log('FILE SUCCESSFULLY UPLOADED')
            // console.log(blob.metadata.url)
            // console.log('-----------------------')
            this.showAlert(`FILE SUCCESSFULLY UPLOADED\n\n ${blob.filename}`);
            this.uploadFinished = {
              fileName: blob.filename,
              url: blob.metadata.url,
            };
          }
        });
      } else {
        this.showAlert(`File exceeds the ${MAXIMUM_SUPPORTED_FILE_UPLOAD_SIZE} MB attachment limit`);
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.bottom-box {
  @apply flex justify-between py-3 px-4;

  &.is-note-mode {
    @apply bg-yellow-100 dark:bg-yellow-800;
  }
}

.left-wrap {
  @apply items-center flex gap-2 px-4;
}

.right-wrap {
  @apply flex;
}

::v-deep .file-uploads {
  label {
    @apply cursor-pointer;
  }
  &:hover button {
    @apply dark:bg-slate-800 bg-slate-100;
  }
}

.modal-mask {
  @apply text-slate-600 dark:text-slate-200 bg-white_transparent dark:bg-black_transparent flex-col;
}

.icon {
  @apply text-[5rem];
}

.left-wrap {
  margin: 0 auto;
}

.button--upload {
  width: 200px;
  height: 50px;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  font-weight: bold;
}

.upload-message {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

</style>
