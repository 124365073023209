<template>
  <div style="margin: 10px;">
    <p>Uploaded media</p>
    <br/>
    <!-- Try to implement the same CsatTable for the media-table-->
    <!-- app/javascript/dashboard/routes/dashboard/settings/reports/components/CsatTable.vue -->
    <a href="media/upload">
      <fluent-icon
        v-tooltip.right-start="$t('Upload new file')"
        icon="attach"
        class="action--icon cursor-pointer"
        size="16"
      />
      <span>Upload new file</span>
    </a>

    <table class="media-table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Filename</th>
          <th>URL</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="mediaFile in mediaList" :key="mediaFile.id">
          <td>{{ mediaFile.id }}</td>
          <td>{{ mediaFile.filename }}</td>
          <td>
            <a :href="`${mediaFile.metadata.url}`" target="_blank">
              <fluent-icon
                v-tooltip.right-start="$t('Open URL')"
                icon="open"
                class="action--icon cursor-pointer"
                size="16"
              />
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      mediaList: [],
    };
  },
  mounted() {
    this.fetchMediaList();
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      globalConfig: 'globalConfig/get',
      accountId: 'getCurrentAccountId',
    })
  },
  methods: {
    async fetchMediaList() {
      const settings = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'api_access_token': this.currentUser.access_token,
        }
      };
      // Replace with actual API endpoint to fetch media files
      try {
        const response = await fetch(`/api/v1/accounts/${this.accountId}/media_uploads/list`, settings)
        if (response.ok) {
          const data = await response.json();
          this.mediaList = data; // Assuming the data is an array of media files
          console.log('========================')
          console.log(this.mediaList)
          console.log('========================')
        } else {
          console.error('Failed to fetch media files');
        }
      } catch (error) {
        console.error('Error while fetching media files:', error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.media-table {
  margin: 30px;
  width: 100%;
  border-collapse: collapse;
}

.media-table th,
.media-table td {
  border: 1px solid black;
  padding: 5px;
}

.media-table th {
  background-color: #eff7ff;
}
</style>
